<template>
  <div class="recommendPage">
    <div class="tplNav">
      <div class="name">
        <div class="colorPiece"></div>
        <span class="homeTplname">{{ name }}</span>
      </div>
      <div class="more" @click="hanleGetmore">
        <button class="more_btn">
          <i class="iconfont iconshujuku"></i>查看更多
        </button>
        <div class="more_div"></div>
      </div>
    </div>
    <swiper :options="swiperOption" ref="mySwiper" v-if="flag">
      <swiper-slide v-for="(item,cindex) in totalPage" :key="cindex">
        <div class="itemWrap" v-if="flag">
          <!-- <img :src="ossurl + tplsList[cindex].tavatar" alt=""> -->
          <product-item
          ref="productitem"
            v-for="(product, index) in tplsList[cindex]"
            :key="index"
            :name="product.tname"
            :price="product.unitprice"
            :usenumber="product.nowcosttimes"
            :labelname="product.tlname"
            :tavatar="product.tavatar"
            :tcode="product.tcode"
            :ossurl="ossurl"
            :tfids="product.tfids.split(',')"
            :prodid="product.prodid"
            :psid="product.psid"
          />
        </div>
        <div >
          </div>
      </swiper-slide>
      <!-- <swiper-slide>I'm Slide 2</swiper-slide>
                <swiper-slide>I'm Slide 3</swiper-slide> -->
    <div class="swiper-pagination" slot="pagination" ></div>
    <div class="swiper-button-prev swiper" slot="button-prev" style="left:-40px" v-if="totalPage>1" @click="prev()"><a-icon type="left" /></div>
    <div class="swiper-button-next swiper" slot="button-next" style="right:-40px" v-if="totalPage>1" @click="next()"><a-icon type="right" /></div>
    </swiper>
    <!-- <a-carousel arrows :dots="false">
    <div
      slot="prevArrow"
      class="custom-slick-arrow"
      style="left: -40px;zIndex: 1"
    >
      <a-icon type="left"/>
    </div>
    <div slot="nextArrow"  class="custom-slick-arrow" style="right: -35px">
      <a-icon type="right" />
    </div>
   <div v-for="(item,cindex) in totalPage" :key="cindex">
      <div  class="itemWrap" v-if="tplsList[cindex].length>0">
          <product-item
          class="product-item"
          ref="productitem"
            v-for="(product, index) in tplsList[cindex]"
            :key="index"
            :name="product.tname"
            :price="product.unitprice"
            :usenumber="product.nowcosttimes"
            :labelname="product.tlname"
            :tavatar="product.tavatar"
            :tcode="product.tcode"
            :ossurl="ossurl"
            :tfids="product.tfids.split(',')"
            :prodid="product.prodid"
            :psid="product.psid"
          />
    </div>
   </div>
  </a-carousel> -->
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import ProductItem from "@/components/ProductItem";
import "swiper/dist/css/swiper.css";
import { getShowtpl } from "@/api/manage";
export default {
  components: {
    swiper,
    swiperSlide,
    ProductItem,
  },
  data() {
    return {
      tplsList: [],
      list:[[]],
      totalPage:0,
      swiperOption:{
       slidesPerView: 1,
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
             disabledClass: 'my-button-disabled',
          },
          //滑到最后一个隐藏前进按钮
  on: {
    slideChangeTransitionEnd: function(){
      if(this.isEnd){
        this.navigation.$nextEl.css('display','none');
      }else{
        this.navigation.$nextEl.css('display','block');
      }
    },
  },

      },
      flag:false,
      currentPage:1
    }
  },
  created(){
 this.gettplList(1)
  },
    methods:{
      next(){
        this.currentPage++
      },
      prev(){
        this.currentPage--
      },
      hanleGetmore(){
          this.$router.push({ path: "/store/search",query:{prodid:this.prodid,psid:this.psid} })
      },
    gettplList(pageNo){
 let parameter = {
      pageSize: 6,
      psid: this.psid,
      pageNo:pageNo,
      isshow:1
    }
    getShowtpl(parameter).then((res) => {
      if(res.code === 200){
   this.tplsList = res.data.data
   this.list[pageNo - 1] = res.data.data
   if(pageNo <res.data.last_page){
     pageNo++
     this.gettplList(pageNo)
   }else{
     this.tplsList = this.list
     this.flag = true
   }
  if( this.totalPage != res.data.last_page){
     this.totalPage = res.data.last_page
  }
  console.log(this.list);
      }
    })
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    // console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  },
  props: ["name", "psid", "ossurl","prodid"],
};
</script>
<style scoped >
.more {
  position: relative;
  cursor: pointer;
  width: 100px;
  height: 35px;
  box-shadow: 3px 8px 4px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
}
.more_div {
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: 0;
  width: 100px;
  height: 35px;
  background-image: linear-gradient(to right, #085cf5, #27bbee);
  border-radius: 20px;
  border: 1px solid #0d5097;
  box-shadow: inset 1px 3px 20px #002372;
  z-index: 2;
}
.more_btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 35px;
  background-image: linear-gradient(to right, #0052ff, #27bbee);
  border: 1px solid #00e0fe;
  border-radius: 20px;
  color: #fff;
  z-index: 3;
}
.recommendPage{
  position: relative;
}
.recommendPage .colorPiece {
  width: 4px;
  height: 25px;
  background-image: linear-gradient(#0052ff, #27bbee);
  border-radius: 2px;
  display: inline-block;
  margin-right: 22px;
}
.homeTplname {
  font-size: 30px;
  font-weight: 700;
  color: #333;
}
.itemWrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-width:1280px;
}
.itemWrap .product-item{
  margin-right: 10px;
}
.recommendPage .tplNav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  min-width: 1280px;
}
.recommendPage .swiper-container {
  position: initial;
  width: 100%;
  height: auto;
  padding-bottom: 8px;
  margin-bottom: 30px;
  /* background: pink; */
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  /* line-height: 200px; */
  background: #fff;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 40px;
  height: 40px;
  font-size: 16px;
  color: #666;
  border-radius: 50%;
  opacity: 1;
  background-color: #fff;
  line-height: 40px;
  text-align: center;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.16);
  font-weight: 500;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 1;
}
.ant-carousel >>> .slick-list{
    border-radius: 8px 8px 8px 8px;
    padding-bottom: 10px;
        min-width:1280px;
}
.ant-input-affix-wrapper >>> .ant-input{
  border-radius: inherit;
}
.ant-carousel {
  min-width: 1280px;
  position: relative;
}
.swiper-pagination{
  display: none;
}
.swiper{
   width: 40px;
  height: 40px;
  font-size: 16px;
  color: #666;
  border-radius: 50%;
  opacity: 1;
  background-color: #fff;
  line-height: 40px;
  text-align: center;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.16);
  font-weight: 500;
  background-image: none;
  cursor: pointer;
  z-index: 1;
}
.my-button-disabled{
  display: none;
}
</style>

