<template>
    <div class="banner">
<a-carousel arrows >
    <div
      slot="prevArrow"
      class="custom-slick-arrow"
      style="left: 10px;zIndex: 1"
    >
      <a-icon type="left-circle"/>
    </div>
    <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px">
      <a-icon type="right-circle" />
    </div>
    <div v-for="(item,index) in bannerList" :key="index" class="bannerlist" :style="{backgroundImage:'url('+`${ossurl + item}`+')',display:'flex'}">
      <div class="banner_search">
      <div class="banner_text"><h2>XE模板商城 来就送会员</h2></div>
      <div class="search">
          <div class="search_icon">
            <a-icon  type="search" />
          </div>
        <a-input  placeholder="手机海报" class="search_input" @pressEnter="search"/>
    </div>
    <div class="hot_search">
      <span> 热门搜素:</span><span class="hot_searchText" v-for="(item,index) in hotsearch" :key="index" @click="handleHotSearch(item)">{{item}}</span>
    </div>
  </div>
    </div>
  </a-carousel>
    </div>
</template>
<script>
import data from '@/assets/data.json'
export default {
  data(){
    return{
      hotSearch:data.hotSearch,
      bannerImage:data.banner
    }
  },
  methods:{
    search(e){
       //从路由最后新增参数
        let  currentRoute = JSON.parse(JSON.stringify(this.$router.currentRoute.query)) // 这里我们需要的应该是值，因此必须转为深拷贝
         Object.assign(currentRoute, {keywords:e.target.value})
        this.$router.push({path: '/store/search',query:currentRoute})
    },
    handleHotSearch(text){
 //从路由最后新增参数
        let  currentRoute = JSON.parse(JSON.stringify(this.$router.currentRoute.query)) // 这里我们需要的应该是值，因此必须转为深拷贝
         Object.assign(currentRoute, {keywords:text})
        this.$router.push({path: '/store/search',query:currentRoute})
    }
  },
  props:["hotsearch","bannerList","ossurl"]
}
</script>

<style lang="less" scoped>
.banner_search{
  width: 520px;
    .hot_search{
      color: #fff;
     text-align: center;
      .hot_searchText{
        cursor: pointer;
      }
      .hot_searchText:hover{
        border-bottom: 1px solid #fff;
      }
      span{
        margin-right:7px;
      }
    }
    .banner_text{
      text-align: center;
       h2{
           color: #fff;
           font-weight: 700;
           font-size: 36px;
           margin-bottom: 10px;
           display: inline-block;
       }
    }
    .search{
         width: 400px;
   height: 38px;
 border-radius: 20px;
 background-color: #fff;
 padding: 0 10px;
display: flex;
justify-content: space-between;
align-items: center;
margin: 0 auto;
margin-bottom: 17px;
 .search_icon{
   font-size: 20px;
 }
 .search_input{
  border: none;
    }
    .search_input:focus{
      box-shadow:none;
    }
    }

}
.banner{
    margin-bottom: 30px;
    position: relative;
    .bannerlist{
      width: 100%;
      min-width: 1200px;
      height: 240px;
           display: flex !important;
      align-items: center;
      justify-content: center;
      background-repeat:no-repeat;
      background-size:cover;
      background-position:50%;

      img{
        width: 100%;
          min-width: 1280px;
      }
    }
}
</style>
<style scoped>
.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.5;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 1;
}
.ant-carousel >>> .slick-list{
    border-radius: 8px 8px 8px 8px;
    min-width: 1280px;
}
.ant-input-affix-wrapper >>> .ant-input{
  border-radius: inherit;
}
.ant-carousel {
  min-width: 1280px;
  position: relative;
}
</style>