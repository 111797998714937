<template>
  <div>
    <!-- 轮播图部分 -->
    <banner :hotsearch="hotsearch" :ossurl="ossUrl" :bannerList="bannerList"></banner>
    <!-- 实例部分 -->
    <swiper
      v-for="(item, index) in Hometplspecconfig"
      :key="index"
      :name="Hometplspecconfig[index].psname"
      :psid="Hometplspecconfig[index].psid"
      :ossurl="ossUrl"
      :prodid="Hometplspecconfig[index].prodid"
    ></swiper>
  </div>
</template>

<script>
import { getHometplspecconfig,getTplhotwords,getBannerbList } from "@/api/home";
import swiper from "@/components/swiper";
import banner from "@/components/banner";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    swiper,
    banner,
  },
  data() {
    return {
      Hometplspecconfig: [],
      hotsearch:[],
      bannerList:[]
    };
  },
  created() {
    getHometplspecconfig().then((res) => {
      this.Hometplspecconfig = res.data;
    });
    getTplhotwords().then((res)=>{
     this.hotsearch = res.data.split(',')
    })
    getBannerbList().then((res)=>{
      this.bannerList = res.data
    })
  },
  methods: {},
  computed:{
       ...mapGetters(["ossUrl"])
  }
};
</script>
